import React, { useState } from 'react';
import { Input, message } from 'antd';
import { BiSend } from 'react-icons/bi';
import axios from 'axios';
import { API_URL } from '../../config/api.config';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(API_URL + '/send-email', formData);
            message.success('Your message has been sent successfully!');
            setFormData({name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',})
        } catch (error) {
            console.error('Error sending email:', error);
            message.error('There was an error sending your message. Please try again later.');
        }
    };

    return (
        <section className="landing-contact" style={{ backgroundImage: "url(/assets/images/landing-contact-background.jpg)" }}>
            <div className="container contact-form" style={{ backgroundImage: "url(/assets/images/contact-form-background.png)" }}>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <img src="/assets/images/doctor-image.png" alt="" height={"auto"} width={"100%"} />
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="pt-5">
                            <h3 className="section-title">
                                For Queries, Chat with Our Specialist Physicians
                            </h3>
                            <p className="section-description">
                                On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.
                            </p>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="row gy-3">
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Your full name"
                                        size="lg"
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Your email"
                                        type="email"
                                        size="lg"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Your Phone number"
                                        type="number"
                                        size="lg"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Subject"
                                        type="text"
                                        size="lg"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <Input.TextArea
                                        rows={5}
                                        placeholder="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <button className="hover-button" style={{ minWidth: 200 }} type="submit">
                                        <span className="text">Send</span>
                                        <BiSend />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
